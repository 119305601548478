import React, { useState } from "react";

// Libraries
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
// import { colors } from 'utils/variables/'

// Icons
// import IconCaret from "assets/icons/icon-caret-right.inline.svg";

const data = require("../../assets/data/data.json");

const StyledDropdown = styled.div`
  position: relative;
  font-size: 17px;
  font-weight: 500;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 0.12em;

  .dropdown__toggler {
    svg {
      width: 6px;
      height: auto;
      transform: rotate(${(props) => (props.active ? "270deg" : "90deg")});
      transition: all 0.3s ease;
    }
  }

  .dropdown__options {
    min-width: 236px;
    position: absolute;
    top: calc(100% + 12px);
    left: calc((100% - 236px) / 2);
    border-radius: 4px;
    box-shadow: 0px 10px 36px rgba(18, 78, 120, 0.08);
    transform: translateY(${(props) => (props.active ? "0" : "-16px")});
    opacity: ${(props) => (props.active ? "1" : "0")};
    visibility: ${(props) => (props.active ? "visible" : "hidden")};
    transition: all 0.3s ease;

    button {
      padding: 12px 24px;
    }
  }
`;

const Dropdown = (props) => {
  const { options, defaultOption, callbackFunction, className } = props;

  const [active, setActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    defaultOption || options[0]
  );

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setActive(false);
    callbackFunction(option);
  };

  return (
    <StyledDropdown className={className} active={active}>
      <button
        type="button"
        className="dropdown__toggler d-flex align-items-center"
        // onClick={() => setActive(!active)}
      >
        {data.country}
        {/* {selectedOption} */}
        {/* <IconCaret className="ms-2 svg-fill--magenta" /> */}
      </button>

      {/* <ul className="dropdown__options bg--blanco">
        {options.map((option) => (
          <li key={option}>
            {option === selectedOption ? (
              <button
                type="button"
                className="color--magenta"
                onClick={() => handleOptionChange(option)}
              >
                {option}
              </button>
            ) : (
              <button type="button" onClick={() => handleOptionChange(option)}>
                {option}
              </button>
            )}
          </li>
        ))}
      </ul> */}
    </StyledDropdown>
  );
};

Dropdown.propTypes = {
  options: PropTypes.instanceOf(PropTypes.array).isRequired,
  defaultOption: PropTypes.string,
  callbackFunction: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  defaultOption: null,
  className: null,
};

export default Dropdown;
