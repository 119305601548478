import React from "react";

// Libraries
import styled from "styled-components";

// Utils
import { urls } from "utils/variables";

// Icons
import IconCall from "assets/icons/icon-call.inline.svg";
import ChatWidget from "components/chat-widget";

const StyledButtonCall = styled.div`
  position: fixed;
  left: 84px;
  bottom: 26px;
  z-index: 8999;

  @media (max-width: 768px) {
    left: 26px;
  }

  .callbot {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.32);

    &--call {
      background-color: #d53968;

      svg {
        height: 30px !important;
      }
    }

    svg {
      width: auto;
      height: 30px;
    }
  }
`;

const Chatbots = () => {
  return (
    <>
      <StyledButtonCall>
        <a
          href={urls.phoneNumber}
          className="callbot callbot--call"
          rel="noopener noreferrer"
          title="Llamar"
        >
          <IconCall className="svg-fill--blanco" />
        </a>
      </StyledButtonCall>
      <ChatWidget></ChatWidget>
    </>
  );
};

export default Chatbots;
