import React, { useEffect } from "react"

// Libraries
import PropTypes from "prop-types"
import { motion } from "framer-motion"

// Styles
import "assets/styles/reset.css"
import GlobalStyles from "assets/styles/globalStyles"

// Components
import Seo from "components/seo/"
import Menu from "components/menu/"
import Footer from "components/footer"
import Chatbots from "components/chatbots"

const Layout = ({ children }) => {
  // Creates Parallax effect
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll("[data-parallax]")

      elements.forEach((element) => {
        const { offsetTop } = element
        const scrollPosition = window.scrollY
        const direction = element.getAttribute("data-parallax")

        if (direction === "vertical") {
          element.style.transform = `translateY(${
            (scrollPosition - offsetTop) * -0.1
          }px)`
        } else {
          element.style.transform = `translateX(${
            (scrollPosition - offsetTop) * -0.1
          }px)`
        }
      })
    }

    if (window.innerWidth >= 1024) {
      handleScroll()
      document.addEventListener("scroll", handleScroll, { passive: true })

      return () => document.removeEventListener("scroll", handleScroll)
    }

    return null
  }, [])
  return (
    <motion.main
      animate={{ opacity: [0, 1] }}
      transition={{ duration: 1 }}
      style={{ opacity: 0 }}
    >
      <Seo />
      <GlobalStyles />
      <Menu />
      {children}
      <Footer />
      <Chatbots />
    </motion.main>
  )
}

Layout.propTypes = {
  children: PropTypes.element,
}

Layout.defaultProps = {
  children: null,
}

export default Layout
