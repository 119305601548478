import React from "react"

// Libraries
import { navigate } from "gatsby"

// Components
import Dropdown from "components/dropdown/"

const CountriesDropdown = () => {
  const countriesDesktop = [
    "Argentina",
    "Colombia",
    "Paraguay",
    "Perú",
    "Uruguay",
  ]

  const countriesMobile = ["Arg", "Col", "Par", "Per", "Uru"]

  const countriesRedirections = {
    Argentina: "/",
    Arg: "/",
    Colombia: "/col",
    Col: "/col",
    Paraguay: "/par",
    Par: "/par",
    Perú: "/per",
    Per: "/per",
    Uruguay: "/uru",
    Uru: "/uru",
  }

  const handleCountryChange = (option) => {
    navigate(countriesRedirections[option])
  }

  return (
    <>
      <Dropdown
        className="d-sm-none"
        options={countriesMobile}
        callbackFunction={handleCountryChange}
      />
      <Dropdown
        className="d-none d-sm-block"
        options={countriesDesktop}
        callbackFunction={handleCountryChange}
      />
    </>
  )
}

export default CountriesDropdown
