// Libraries
import { css } from "styled-components";

// Utils
import { colors, weights, gradients } from "utils/variables/";

const Helpers = () => {
  let styles = "";

  Object.entries(weights).forEach(([name, weight]) => {
    styles += `
      // Font-weights classes
      .font-weight--${name} {
        font-weight: ${weight};
      }
    `;
  });

  Object.entries(gradients).forEach(([name, gradient]) => {
    styles += `
      // Gradients classes
      .gradient--${name} {
        background: ${gradient};
      }
    `;
  });

  Object.entries(colors).forEach(([name, color]) => {
    styles += `
      // Text color classes
      .color--${name} {
        color: ${color};
      }

      // Hover color classes
      .color-hover--${name} {
        
        &:hover {
          color: ${color};
        }
      }

      // Background color classes
      .bg--${name} {
        background-color: ${color};
      }

      .bg-hover--${name} {
        
        &:hover {
          background-color: ${color};
        }
      }

      // Borders
      .border--${name} {
        border-color: ${color};
      }

      .border-hover--${name} {
        
        &:hover {
          border-color: ${color};
        }
      }

      // SVG Fills
      .svg-fill--${name} {
      
        *,
        svg * {
          fill: ${color};
        }
      }

      .svg-hover--fill-${name} {
      
        &:hover {
          *,
          svg * {
            fill: ${color};
          }
        }
      }

      // SVG Strokes
      .svg-stroke--${name} {

        *,
        svg * {
          stroke: ${color};
        }
      }

      .svg-hover--stroke-${name} {

        &:hover {
          *,
          svg * {
            stroke: ${color};
          }
        }
      }
    `;
  });

  return css`
    ${styles}
  `;
};

export default Helpers;
