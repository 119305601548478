import React from "react";
import styled from "styled-components";

// Variables
import { colors } from "utils/variables/";
import breakpoint from "utils/breakpoints";

import { Link } from "gatsby";

// Logo Genesia
import Genesia from "assets/icons/logo-genesia.inline.svg";

// Icons
import Facebook from "assets/icons/footer/facebook.inline.svg";
import Instagram from "assets/icons/footer/instagram.inline.svg";
import Linkedin from "assets/icons/footer/linkedin.inline.svg";

// Components
import Container from "components/container";

const data = require("../../assets/data/data.json");

const StyledFooter = styled.footer`
  padding: 50px 0px 40px 0px;
  text-align: center;
  .logo {
    z-index: 9998;
  }
  .footer__block {
    ${breakpoint.medium`
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      align-content: center;
    `}

    .footer__links {
      margin-top: 64px;
      margin-bottom: 79px;
      white-space: nowrap;

      ${breakpoint.medium`
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
      `}

      ul {
        ${breakpoint.medium`
          display: flex;
          aling-items: center;
          justify-content: center;
        `}

        li {
          margin-bottom: 54px;

          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            color: ${colors.azul};

            &:hover {
              color: ${colors.magenta};
            }
          }

          ${breakpoint.medium`
            margin-right: 25px;
            margin-bottom: 0;
            &:last-of-type {
              margin-right: 0;
            }
          `}
          ${breakpoint.large`
            margin-right: 54px;
            &:last-of-type {
              margin-right: 0;
            }
          `}
        }
      }
    }

    .footer__icons {
      display: flex;
      justify-content: center;

      ${breakpoint.medium`
        justify-content: space-between;
        align-items: center;
      `}

      a {
        z-index: 9999;
        display: flex;
        margin-right: 50px;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
        ${breakpoint.medium`
          margin-right: 40px;
        `}
        &:hover {
          svg {
            * {
              fill: ${colors.azul};
            }
          }
        }
      }
    }
  }

  .footer__privacy {
    margin-top: 58px;
    white-space: nowrap;
    ${breakpoint.medium`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 71px;

    `}

    a {
      color: ${colors.magenta};
      font-weight: 600;
      font-size: 14px;

      &:hover {
        color: ${colors.azul};
      }
    }

    p {
      margin-top: 16px;
      color: ${colors.azul};
      font-weight: 500;

      ${breakpoint.medium`
        margin-top:0;
        margin-left: 16px;
      `}
    }
  }
`;

const Footer = () => {
  const socialLinks = [
    {
      title: "Facebook",
      url: "https://www.facebook.com/genesia.adn",
      icon: <Facebook />,
    },
    {
      title: "Instagram",
      url: "https://www.instagram.com/genesia.adn/",
      icon: <Instagram />,
    },
    {
      title: "Linkedin",
      url: "https://www.linkedin.com/company/genesia-medicina-personalizada/",
      icon: <Linkedin />,
    },
  ];
  return (
    <StyledFooter>
      <Container>
        <div className="footer__block">
          <Link to="#home" className="logo">
            <Genesia />
          </Link>

          <div className="footer__links">
            <ul>
              <li>
                <a href="#opiniones" className="button-small font-weight--700">
                  Opiniones
                </a>
              </li>
              {data.testimonials?.length && (
                <li>
                  <a
                    href="#experiencias"
                    className="button-small font-weight--700"
                  >
                    Experiencias
                  </a>
                </li>
              )}
              <li>
                <a href="#que-es" className="button-small font-weight--700">
                  ¿Qué es?
                </a>
              </li>
              <li>
                <a
                  href="#como-se-hace"
                  className="button-small font-weight--700"
                >
                  ¿Cómo se hace?
                </a>
              </li>
              <li>
                <a
                  href="#tipos-de-tests"
                  className="button-small font-weight--700"
                >
                  Tipos de tests
                </a>
              </li>
              <li>
                <a
                  href="#sobre-nosotros"
                  className="button-small font-weight--700"
                >
                  Sobre nosotros
                </a>
              </li>
            </ul>
          </div>

          <div className="footer__icons">
            {socialLinks.map((link) => (
              <a
                key={link.url}
                href={link.url}
                target="_blank"
                rel="noreferrer"
                title={link.title}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>

        <div className="footer__privacy">
          <a
            href="/aviso-de-privacidad-mx.pdf"
            target="_blank"
            className="micro-text"
          >
            Aviso de privacidad
          </a>
          <p className="micro-text">
            Genesia © {new Date().getFullYear()} | All Rights Reserved
          </p>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
