// Libraries
import { createGlobalStyle } from "styled-components"

// Styles
import "./styles.scss"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Helpers
import Helpers from "./helpers"

const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  // Disables scroll bounce on Safari
  html,
  body {
    overscroll-behavior-y: none;
  }

  .no-scroll {
    overflow: hidden !important;
  }

  body {
    padding-top: 47px;
    font-family: "Montserrat", sans-serif;
    background: ${colors.blanco};
    color: ${colors.azul};
    
    ${breakpoint.medium`
      padding-top: 80px;
    `}

    h1 {
      font-size: 40px;
      line-height: 48px;
      
      ${breakpoint.medium`
        font-size: 56px;
        line-height: 72px;
      `}
    }

    h2 {
      font-size: 40px;
      line-height: 48px;
      font-weight: 400;

      ${breakpoint.medium`
        font-size: 48px;
      `}
    }

    h3 {
      font-size: 32px;
      line-height: 44px;
      font-weight: 500;

      ${breakpoint.medium`
        font-size: 36px;
      `}
    }

    h4 {
      font-size: 20px;
      line-height: 32px;
    }

    p {
      font-size: 24px;
      line-height: 43px;

      &.paragraph--medium {
        font-size: 20px;
        line-height: 36px;
      }

      &.paragraph--small {
        font-size: 16px;
        line-height: 29px;
        letter-spacing: -0.01em;
      }

      &.micro-text {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
      }

      &.subtitulo {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 5%;
      }
    }

    button {
      all: unset;
      background: 0;
      padding: 0;
      margin: 0;
      border: 0;
      color: inherit;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .button-large {
      font-size: 16px;
      line-height: 16px;
    }

    .button-small {
      font-size: 14px;
      font-weight: 16px;
    }

    .primary__button {
      padding: 20px 123px;
      background-color: ${colors.magenta};
      color: ${colors.blanco};
    }

    .cta__button {
      background-color: ${colors.azul};
      padding: 17px 75px;
      color: ${colors.blanco};
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 24px;

      ${breakpoint.large`
        padding: 17px 39px;
      `}
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s ease;
    }
  }

  ${Helpers}
`

export default GlobalStyles
