import React, { useState, useEffect, useRef } from "react";

// Libraries
import styled from "styled-components";

// Icons
import IconWhatsapp from "assets/icons/icon-whatsapp.inline.svg";
import IconClose from "assets/icons/icon-close.inline.svg";

import { StaticImage } from "gatsby-plugin-image";
import IconLogo from "assets/icons/logo-genesia.inline.svg";

const data = require("../../assets/data/data.json");

const StyledButtonWhatsapp = styled.div`
  position: fixed;
  right: 84px;
  bottom: 26px;
  z-index: 8999;

  @media (max-width: 768px) {
    right: 26px;
  }

  .chatbot {
    cursor: pointer;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.32);

    &--whatsapp {
      background-color: #25d366;

      svg {
        height: 35px !important;
      }
    }

    svg {
      width: auto;
      height: 30px;
    }
  }

  .bg-white {
    background-color: #fff !important;
  }
`;

const StyledPreview = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  right: 84px;
  bottom: 100px;
  width: 90%;
  max-width: 400px;
  height: 100px;
  background-color: #fff;
  z-index: 8999;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.32);

  @media (max-width: 768px) {
    right: 26px;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 40px;
  }
  .whatsapp {
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
    background-color: #25d366;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const StyledChat = styled.div`
  position: fixed;
  width: 90%;
  max-width: 400px;
  bottom: 26px;
  height: 500px;
  max-height: 65vh;
  background-color: #fff;
  right: 84px;
  z-index: 8999;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.32);
  bottom: 100px;
  border-radius: 20px;

@media (max-width: 768px) {
  right: 26px;
}
  .header {
    border-radius: 20px 20px 0 0;
    width: 100%
    height: 50px;
    margin-bottom: 20px;
    background-color: #f8e9f0;
    padding: 10px;
    .whatsapp {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: #25d366;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display:flex;
      align-items:center;
      justify-content:center;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .bubble {
    color: #fff;
    width: 70%;
    margin-left: 20%;
    background-color: #d53968;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  .presets {
    width: 100%;
    position: absolute;
    bottom: 60px;
    button {
      width: 85%;
      margin: 10px 5%;
      cursor: pointer;
      color: #d53968;
      border: 1px solid #d53968;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  .footer {
    width:100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d53968;
    position: absolute;
    bottom: 0;
    input {
      border-radius: 0 0 0 20px;
      background-color: #eee;
      width: 80%;
      height: 100%;
      border: none;
      padding: 0 10px;
      font-size: 14px;
      line-height: 1.5;
    }
    .button-chat {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      cursor: pointer;
    }
  }
`;

const ChatWidget = () => {
  const [showChat, setShowChat] = useState(false);
  const [showChatPreview, setShowChatPreview] = useState(false);
  const [message, setMessage] = useState("");
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setTimeout(() => {
      if (!showChatPreview && !showChat) setShowChatPreview(true);
    }, 5000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const showChatWindow = () => {
    setShowChat(true);
    setShowChatPreview(false);
    setMessage("");
    clearInterval(intervalRef.current);
  };

  const sendWhatsappMessage = async (message = "") => {
    if (message) setMessage(message);
    const urlParams = new URLSearchParams(window.location.search);
    const gclid = urlParams.get("gclid");
    let url = `https://api.whatsapp.com/send?phone=${data.whatsApp}&text=`;
    if (gclid) {
      await fetch("https://api.genesia.la/", {
        method: "post",
        body: JSON.stringify({
          action: "gclid_save",
          gclid,
        }),
      }).then(async (response) => {
        if (response.status === 200) {
          await response.json().then((data) => {
            const gclidMessage = `N° de caso: ${data} (no borrar por favor)\n\n`;
            url += gclidMessage;
          });
        }
      });
    }
    url += message;
    url = encodeURI(url);
    window.open(url, "_self");
  };

  return (
    <>
      <StyledButtonWhatsapp>
        <span
          className={
            "chatbot chatbot--whatsapp " +
            (showChatPreview || showChat ? "bg-white" : "")
          }
          title="WhatsApp"
        >
          {!showChatPreview && !showChat && (
            <IconWhatsapp
              className="svg-fill--blanco"
              onClick={() => sendWhatsappMessage()}
            />
          )}
          {(showChatPreview || showChat) && (
            <IconClose
              onClick={() => {
                setShowChatPreview(false);
                setShowChat(false);
              }}
              className="svg-fill--blanco"
            />
          )}
        </span>
      </StyledButtonWhatsapp>
      {showChatPreview && (
        <StyledPreview onClick={() => sendWhatsappMessage()}>
          <StaticImage
            src="../../assets/images/icon.png"
            alt="Genesia"
            width={50}
          />
          <p className="text-sm text-gray-500 pl-2">
            Hola, estoy disponible, ¿te puedo ayudar con algo?
          </p>
          <div className="whatsapp">
            <IconWhatsapp className="svg-fill--blanco" />
          </div>
        </StyledPreview>
      )}
      {showChat && (
        <StyledChat>
          <div className="header">
            <IconLogo />
            <div className="whatsapp">
              <IconWhatsapp className="svg-fill--blanco" />
            </div>
          </div>
          <p className="bubble">
            Hola, estoy disponible, ¿te puedo ayudar con algo?
          </p>
          <div className="presets">
            <button
              onClick={() =>
                sendWhatsappMessage(
                  "Quiero información y precios sobre el Test prenatal no invasivo"
                )
              }
            >
              Quiero información y precios sobre el Test prenatal no invasivo
            </button>
            <button
              onClick={() =>
                sendWhatsappMessage(
                  "Quiero información y precios sobre otros tests"
                )
              }
            >
              Quiero información y precios sobre otros tests
            </button>
          </div>
          <div className="footer">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Ingrese su mensaje"
            />
            <div
              className="button-chat"
              onClick={() => sendWhatsappMessage(message)}
            >
              Enviar
            </div>
          </div>
        </StyledChat>
      )}
    </>
  );
};

export default ChatWidget;
