const data = require("../../assets/data/data.json");

// Breakpoints:start
export const breakpoints = {
  small: "768",
  medium: "1024",
  large: "1200",
  extraLarge: "1440",
  wide: "2000",
};
// Breakpoints:end

// Colors:start
export const colors = {
  // Insert your variables here
  azul: "#124E78",
  rosa: "#F3ACB0",
  magenta: "#D53968",
  verde: "#169873",
  blanco: "#FFFFFF",
  gris: "#FAFAFA",
  gris__2: "#C4CDD3",
  gris__3: "#6C787F",
  negro: "#303749",
};
// Colors:end

export const gradients = {
  blue: "linear-gradient(137.94deg, #124E78 23.72%, #061D2E 122.68%)",
  pink: "linear-gradient(137.94deg, #F3ACB0 23.72%, #D2737A 122.68%)",
  magenta: "linear-gradient(137.94deg, #D53968 23.72%, #A52B4F 122.68%)",
};
// Font Weights:start
export const weights = {
  400: "400",
  500: "500",
  600: "600",
  700: "700",
};
// Font Weights:end

// Phone Number:start
export const whatsApp = data.whatsApp;
export const phoneNumberForCalls = "+" + data.phoneNumber;
// Phone Number:end

// URL's:start
export const urls = {
  whatsapp: whatsApp ? `https://wa.me/${whatsApp}` : null,
  phoneNumber: phoneNumberForCalls ? `tel:${phoneNumberForCalls}` : null,
};
// URL's:end
